const symbols = {
    'BNB/BTC': 'hBNBBT',
    'BNB/ETH': 'hBNBET',
    'BNB/PECU': 'hBNBPE',
    'BNB/EUR': 'hBNBEU',
    'BNB/GBP': 'hBNBGB',
    'BNB/XAU': 'hBNBXA',
    'BNB/XPT': 'hBNBXP',
    'BNB/XPD': 'hBNBXP',
    'PECU/BTC': 'hPECUBT',
    'PECU/BNB': 'hPECUBN',
    'PECU/ETH': 'hPECUET',
    'PECU/GBP': 'hPECUGB',
    'PECU/EUR': 'hPECUEU',
    'PECU/XAU': 'hPECUXA',
    'PECU/XPT': 'hPECUXP',
    'PECU/XPD': 'hPECUXP',
    'PECU/JPY': 'hPECUJP',
    'PECU/CHF': 'hPECUCH',
    'PECU/HKD': 'hPECUHK',
    'PECU/CNY': 'hPECUCN',
    'PECU/NZD': 'hPECUNZ',
    'PECU/AED': 'hPECUAE',
    'PECU/JOD': 'hPECUJO',
    'PECU/RUS': 'hPECURU',
    'PECU/SAR': 'hPECUSA',
    'PECU/SGD': 'hPECUSG',
    'PECU/TWD': 'hPECUTW',
    'PECU/SEK': 'hPECUSE',
    'PECU/TRY': 'hPECUTR',
    'PECU/INR': 'hPECUIN',
    'PECU/EGP': 'hPECUEG',
    'PECU/GHS': 'hPECUGH',
    'PECU/HUF': 'hPECUHU',
    'PECU/IDR': 'hPECUID',
    'PECU/ILS': 'hPECUIL',
    'PECU/KRW': 'hPECUKR',
    'PECU/MXN': 'hPECUMX',
    'PECU/MYR': 'hPECUMY',
    'PECU/THB': 'hPECUTH',
    'PECU/UAH': 'hPECUUA',
    'PECU/NOK': 'hPECUNO',
    'PECU/PHP': 'hPECUPH',
    'PECU/PLN': 'hPECUPL',
    'PECU/PYD': 'hPECUPY',
    'BTC/EUR': 'hBTCEU',
    'BTC/GBP': 'hBTCGB',
    'BTC/XAU': 'hBTCXA',
    'BTC/XPT': 'hBTCXP',
    'BTC/XPD': 'hBTCXP',
    'BTC/WTI': 'hBTCWT',
    'BTC/XBR': 'hBTCXB',
    'BTC/ETH': 'hBTCET',
    'BTC/BNB': 'hBTCBN',
    'BTC/PECU': 'hBTCPE',
    'BTC/JPY': 'hBTCJP',
    'BTC/CHF': 'hBTCCH',
    'BTC/HKD': 'hBTCHK',
    'BTC/CNY': 'hBTCCN',
    'BTC/NZD': 'hBTCNZ',
    'BTC/AED': 'hBTCAE',
    'BTC/JOD': 'hBTCJO',
    'BTC/RUS': 'hBTCRU',
    'BTC/SAR': 'hBTCSA',
    'BTC/SGD': 'hBTCSG',
    'BTC/TWD': 'hBTCTW',
    'BTC/SEK': 'hBTCSE',
    'BTC/TRY': 'hBTCTR',
    'BTC/INR': 'hBTCIN',
    'BTC/EGP': 'hBTCEG',
    'BTC/GHS': 'hBTCGH',
    'BTC/HUF': 'hBTCHU',
    'BTC/IDR': 'hBTCID',
    'BTC/ILS': 'hBTCIL',
    'BTC/KRW': 'hBTCKR',
    'BTC/MXN': 'hBTCMX',
    'BTC/MYR': 'hBTCMY',
    'BTC/THB': 'hBTCTH',
    'BTC/UAH': 'hBTCUA',
    'BTC/NOK': 'hBTCNO',
    'BTC/PHP': 'hBTCPH',
    'BTC/PLN': 'hBTCPL',
    'BTC/PYD': 'hBTCPY',
    'LTC/EUR': 'hLTCEU',
    'LTC/GBP': 'hLTCGB',
    'LTC/XAU': 'hLTCXA',
    'LTC/XPT': 'hLTCXP',
    'LTC/XPD': 'hLTCXP',
    'LTC/WTI': 'hLTCWT',
    'LTC/XBR': 'hLTCXB',
    'LTC/BTC': 'hLTCBT',
    'LTC/BNB': 'hLTCBN',
    'LTC/PECU': 'hLTCPE',
    'LTC/ETH': 'hLTCET',
    'LTC/JPY': 'hLTCJP',
    'LTC/CHF': 'hLTCCH',
    'LTC/HKD': 'hLTCHK',
    'LTC/CNY': 'hLTCCN',
    'LTC/NZD': 'hLTCNZ',
    'LTC/AED': 'hLTCAE',
    'LTC/JOD': 'hLTCJO',
    'LTC/RUS': 'hLTCRU',
    'LTC/SAR': 'hLTCSA',
    'LTC/SGD': 'hLTCSG',
    'LTC/TWD': 'hLTCTW',
    'LTC/SEK': 'hLTCSE',
    'LTC/TRY': 'hLTCTR',
    'LTC/INR': 'hLTCIN',
    'LTC/EGP': 'hLTCEG',
    'LTC/GHS': 'hLTCGH',
    'LTC/HUF': 'hLTCHU',
    'LTC/IDR': 'hLTCID',
    'LTC/ILS': 'hLTCIL',
    'LTC/KRW': 'hLTCKR',
    'LTC/MXN': 'hLTCMX',
    'LTC/MYR': 'hLTCMY',
    'LTC/THB': 'hLTCTH',
    'LTC/UAH': 'hLTCUA',
    'LTC/NOK': 'hLTCNO',
    'LTC/PHP': 'hLTCPH',
    'LTC/PLN': 'hLTCPL',
    'LTC/PYD': 'hLTCPY',
    'ETH/PECU': 'hETHPE',
    'ETH/BNB': 'hETHBN',
    'ETH/BTC': 'hETHBT',
    'ETH/XBR': 'hETHXB',
    'ETH/WTI': 'hETHWT',
    'ETH/XPD': 'hETHXP',
    'ETH/XPT': 'hETHXP',
    'ETH/XAU': 'hETHXA',
    'ETH/GBP': 'hETHGB',
    'ETH/EUR': 'hETHEU',
    'ETH/JPY': 'hETHJP',
    'ETH/CHF': 'hETHCH',
    'ETH/HKD': 'hETHHK',
    'ETH/CNY': 'hETHCN',
    'ETH/NZD': 'hETHNZ',
    'ETH/AED': 'hETHAE',
    'ETH/JOD': 'hETHJO',
    'ETH/RUS': 'hETHRU',
    'ETH/SAR': 'hETHSA',
    'ETH/SGD': 'hETHSG',
    'ETH/TWD': 'hETHTW',
    'ETH/SEK': 'hETHSE',
    'ETH/TRY': 'hETHTR',
    'ETH/INR': 'hETHIN',
    'ETH/EGP': 'hETHEG',
    'ETH/GHS': 'hETHGH',
    'ETH/HUF': 'hETHHU',
    'ETH/IDR': 'hETHID',
    'ETH/ILS': 'hETHIL',
    'ETH/KRW': 'hETHKR',
    'ETH/MXN': 'hETHMX',
    'ETH/MYR': 'hETHMY',
    'ETH/THB': 'hETHTH',
    'ETH/UAH': 'hETHUA',
    'ETH/NOK': 'hETHNO',
    'ETH/PHP': 'hETHPH',
    'ETH/PLN': 'hETHPL',
    'ETH/PYD': 'hETHPY'
  };
  
export default symbols