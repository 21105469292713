import React, { useState, useEffect } from 'react';
import './publicLedger.css';
import "./pecuscan.css"
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, Grid, Tooltip, tooltipClasses, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Modal from "react-bootstrap/Modal";
import { Button } from 'bootstrap';
import EastIcon from "@mui/icons-material/East";
import { Link } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import logo from './pecunovuslogo33.png'
import styled from "@emotion/styled";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import url from '../serverurl';
function getFormattedTime(timestampString) {
  const timestamp = new Date(timestampString);

  // timestamp.setHours(timestamp.getHours() + 8);
  // timestamp.setMinutes(timestamp.getMinutes()-50)
  return timestamp;
}
export default function PublicLedger({ searchBlock }) {
  const [showModal, setShowModal] = useState(false);
  const [modaldata, setModaldata] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  // const data=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
  const [transaction, setTransaction] = useState([]);
  const [limit, setLimit] = useState(1);
  const filterTransaction = (searchBlock) => {
    if (searchBlock && searchBlock !== '') {
      return transaction.filter(
        (e) =>
          e.id?.toString().includes(searchBlock?.toString()) ||
          e.public_key?.toLowerCase().includes(searchBlock?.toLowerCase()) ||
          e.to_public_key?.toLowerCase().includes(searchBlock?.toLowerCase()) ||
          e.value?.toString().includes(searchBlock?.toString())
      );
    } else {
      return transaction;
    }
  };
  const makeid = (length) => {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const scroll = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  const transactionData = () => {
    axios
      .get(`${url}/blockchain/transactions?page=${limit}&pageSize=20`)
      .then((res) => {
        setLimit(limit + 1);
  
        setTransaction(res.data.transactions);
            setIsLoading(false);
      });
  };
  useEffect(() => {
    filterTransaction();
    transactionData();
  }, [showModal])

  const fetchmore = () => {
    axios
      .post(`${url}/wallet/public_ledger_all_data`, {
        minLimit: limit,
        maxLimit: limit + 1
      })
      .then((res) => {
        setLimit(limit + 1);
        let myData = res.data;
        let mydataf = [];
        res.data.forEach((e) => {
          if (e.key && e.key.length > 0) {
            mydataf.push(e);
          } else {
            e.key = makeid(128);
            mydataf.push(e);
          }

          if (res.data.length == mydataf.length) {
            setTransaction([...transaction, ...mydataf]);
          }
        });
      });
  };
  return (
    <>

      {showModal ? (
        <Modal
          size="sm"
          show={showModal}
          aria-labelledby="example-modal-sizes-title-sm"
          className="modalHistory"
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2"><b>Transaction Details</b></Typography>
            <button
              className="close-btn"
              style={{ width: '45px', border: "none" }}
              onClick={() => setShowModal(false)}
            >
              X
            </button>
          </Box>
          <Grid container spacing={2} className="gridBody">
            <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
              Transaction :
            </Grid>
            <Grid item xs={8} className="bold-text gridContent " style={{ color: "#0784c3" }}>
              {/* {modaldata?.public_key} */}
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ margin: '10px 0' }}>
            <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
              Status:
            </Grid>
            <Grid item xs={6} sm={8} className="bold-text gridContent " style={{ color: "#0784c3" }}>
            <span className="successButton">
                  <i>
                    <CheckCircleIcon />
                  </i>
                  Success
                </span>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="gridBody">
            <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
              From:
            </Grid>
            <Grid item xs={8} className="bold-text gridContent " style={{ color: "#0784c3" }}>
              {modaldata?.public_key}
            </Grid>
          </Grid>

          <Grid container spacing={2} className="gridBody">
            <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
              To:
            </Grid>
            <Grid item xs={8} className="bold-text gridContent " style={{ color: "#0784c3" }}>
              {modaldata?.to_public_key}
            </Grid>
          </Grid>
          <hr />

          <Grid container spacing={2} style={{ margin: '10px 0' }}>
            <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
              Block Height:
            </Grid>
            <Grid item xs={6} sm={8} className="bold-text gridContent " >
              {modaldata?.tid}
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ margin: '10px 0' }}>
            <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
              Block Size:
            </Grid>
            <Grid item xs={6} sm={8} className="bold-text gridContent " >
              {/* {modaldata?.block_no} */}

            </Grid>
          </Grid>


          <Grid container spacing={2} style={{ margin: '10px 0' }}>
            <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
              Timestamp:
            </Grid>
            <Grid item xs={8} className="bold-text gridContent">
              <i>
                <AccessTimeIcon />
              </i>
              {new Date(modaldata?.date_time).toUTCString()}
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ margin: '10px 0' }}>
            <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
              Node:
            </Grid>
            <Grid item xs={8} className="bold-text gridContent">
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ margin: '10px 0' }}>
            <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
              Route:
            </Grid>
            <Grid item xs={8} className="bold-text gridContent">
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ margin: '10px 0' }}>
            <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
              Value:
            </Grid>
            <Grid item xs={6} sm={8} className="bold-text gridContent ">
              {modaldata.action.toLowerCase() === 'send'
                ? <img style={{ marginRight: "8px" }} width="16" data-img-theme="light" src={logo} alt="Pecuscan Logo" />
                : ""}
              {modaldata?.value}
            </Grid>
          </Grid>
          <hr />

          <Grid container spacing={2} style={{ margin: '10px 0' }}>
            <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
              Transaction Type:
            </Grid>
            <Grid item xs={6} sm={8} className="bold-text gridContent">
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ margin: '10px 0' }}>
            <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
              Origination:
            </Grid>
            <Grid item xs={6} sm={8} className="bold-text gridContent">
            </Grid>
          </Grid>

          <hr />
          <Grid container spacing={2} className="gridBody">
            <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
              Block Address:
            </Grid>
            <Grid item xs={8} className="bold-text gridContent " style={{ color: "#0784c3" }}>
              {modaldata?.key}
            </Grid>
          </Grid>
         
          <Grid container spacing={2} style={{ margin: '10px 0' }}>
            <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
              Contract key:
            </Grid>
            <Grid item xs={6} sm={8} className="bold-text gridContent">
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ margin: '10px 0' }}>
            <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
              Public notes:
            </Grid>
            <Grid item xs={6} sm={8} className="bold-text gridContent">
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ margin: '10px 0' }}>
            <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
              Private notes:
            </Grid>
            <Grid item xs={6} sm={8} className="bold-text gridContent">
            </Grid>
          </Grid>

        </Modal>
      ) : null}
      <Grid container className="block_shadow" style={{ display: "block" }}>
        <Box className="block_header">
          <Typography> Latest Transactions</Typography>
        </Box>
        <Box style={{justifyContent:'center', display:'flex'}}>{isLoading && <h5>Loading...</h5>}</Box>
        <Box className="block_body">
          {filterTransaction(searchBlock)
            ?.slice(0, 6)
            ?.map((e, index) => {
              return (
                <>
                  <Link to={`/transaction/${e.hash}`} state={e} onClick={scroll}>
                    <Grid
                      spacing={2}
                      sx={{ display: "flex", margin: "16px 0" }}
                      className="resblockContent"
                    // onClick={() => {
                    //   setShowModal(true);
                    //   setModaldata(e);
                    // }}
                    >
                      <Grid xs={6} sx={{ maxWidth: "100%" }}>
                        <Grid
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <Box className="block_icon">
                            <i>
                              <ReceiptIcon />
                            </i>
                          </Box>
                          <Box className="resblockItem">
                            {/* <a className="text-truncate">{parseInt(e.id) - 10}</a> */}
                            {/* <a className="text-truncate" style={{ width: "128px", display: 'block' }}>{e.public_key}</a> */}
                            {/* <Link to={`/transaction/${e.id}`} state={e}><a className="text-truncate" style={{ width: "128px", display: 'block',cursor:'pointer' }}>{e.public_key}</a></Link> */}
                            <Typography className="text-muted">
                              {" "}
                              {getFormattedTime(e.timestamp).toUTCString()}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={6}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          maxWidth: "100%",
                        }}
                      >
                        <Box>
                          <Box className="block_gap">
                            <Typography sx={{ fontSize: "15px" }}>
                              From
                            </Typography>
                            <a
                              className="text-truncate"
                              style={{ maxWidth: "128px" }}
                            >
                              {e.sender}
                            </a>
                          </Box>
                          <Box className="block_gap">
                            <Typography sx={{ fontSize: "15px" }}>
                              To
                            </Typography>
                            <a className="text-truncate" style={{ maxWidth: "128px" }}>{e.recipient} </a>
                          </Box>

                        </Box>
                        <Box>
                          <p className="block_Eth text-truncate" >
                            <b style={{ color: 'black' }}>{e.price} USD</b>
                          </p>
                        </Box>
                      </Grid>
                    </Grid>
                  </Link>

                  <Box className="border_Space"></Box>
                </>
              );
            })}
        </Box>
        <Box className="block_footer text-grey">
          <Link to="/addtransaction" state={transaction}>View all transactions</Link>
          <i>
            <EastIcon />
          </i>{" "}
        </Box>



        {/* </InfiniteScroll> */}
      </Grid>
    </>
  );
}
