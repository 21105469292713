import "./App.css";
import Pecuscan from "./PecuScan/Pecuscan";
import "bootstrap/dist/css/bootstrap.min.css";
import PublicLedger from "./PecuScan/publicLedger";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import AddTransaction from "./PecuScan/AddTransaction/Addtransaction";
import BlockId from "./PecuScan/BlockId/block"
import TransactionId from "./PecuScan/TransactionId/transaction"
import AllBlock from "./PecuScan/AllBlock/allblock";



function App() {
  return (
    <>
      {/* <Pecuscan /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Pecuscan />} />
          <Route path="/allBlock" element={<AllBlock />} />
          <Route path="/addtransaction" element={<AddTransaction />} />
          <Route path="/block/:id" element={<BlockId />} />
          <Route path="/transaction/:id" element={<TransactionId />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
