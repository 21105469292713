import { Box, Grid } from '@mui/material'
import React from 'react'
import Navbar from '../Navbar'
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import logo from '../pecunovuslogo33.png'
import { useLocation } from 'react-router-dom';
import Footer from '../Footer';
import symbols from '../../symbolMapper';


const transValue = (trans) => {
    return (Number(trans.price)*Number(trans.amount)).toFixed(5)
}
const getBLockNumber = (id) => {
    return parseInt(id)+6000000
}
export default function Transaction() {
    const location = useLocation()
    const transactionIdData = location.state;
    return (
        <Box>
            <Navbar />
            <section style={{ backgroundColor: "#fafbfd", paddingBottom: '20px' }}>
                <section className="container">
                    <div className="headerBlock">
                        <div className='blockId'>
                            <h1>Transaction Details</h1>
                        </div>
                    </div>
                    <div className='blockCard'>
                        <Box style={{padding:'8px 18px'}}>
                            <Grid container spacing={2} className="gridBody">
                                <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                    Transaction Hash:
                                </Grid>
                                <Grid item xs={8} className="bold-text gridContent " style={{ color: "#0784c3" }}>
                                    {transactionIdData?.hash}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} style={{ margin: '10px 0' }}>
                                <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                    Status:
                                </Grid>
                                <Grid item xs={6} sm={8} className="bold-text gridContent " style={{ color: "#0784c3" }}>
                                <span className="successButton">
                                        <i>
                                            <CheckCircleIcon />
                                        </i>
                                        Success
                                    </span>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className="gridBody">
                                <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                    From:
                                </Grid>
                                <Grid item xs={8} className="bold-text gridContent " style={{ color: "#0784c3" }}>
                                    {transactionIdData?.sender}
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="gridBody">
                                <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                    To:
                                </Grid>
                                <Grid item xs={8} className="bold-text gridContent " style={{ color: "#0784c3" }}>
                                    {transactionIdData?.recipient}
                                </Grid>
                            </Grid>
                            <hr />

                            <Grid container spacing={2} style={{ margin: '10px 0' }}>
                                <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                    Block Height:
                                </Grid>
                                <Grid item xs={6} sm={8} className="bold-text gridContent " >
                                    {getBLockNumber(transactionIdData.block)}
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} style={{ margin: '10px 0' }}>
                                <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                    Block Size:
                                </Grid>
                                <Grid item xs={6} sm={8} className="bold-text gridContent " >
                                    {128} bytes

                                </Grid>
                            </Grid>
                            <Grid container spacing={2} style={{ margin: '10px 0' }}>
                                <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                    Timestamp:
                                </Grid>
                                <Grid item xs={8} className="bold-text gridContent">
                                    <i>
                                        <AccessTimeIcon />
                                    </i>
                                    {new Date(transactionIdData?.timestamp).toUTCString()}
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} style={{ margin: '10px 0' }}>
                                <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                    Node:
                                </Grid>
                                <Grid item xs={8} className="bold-text gridContent">
                                    {transactionIdData.node}
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} style={{ margin: '10px 0' }}>
                                <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                    Route:
                                </Grid>
                                <Grid item xs={8} className="bold-text gridContent">
                                    {transactionIdData?.route}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} style={{ margin: '10px 0' }}>
                                <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                    Value:
                                </Grid>
                                <Grid item xs={6} sm={8} className="bold-text gridContent ">
                                    {/* {transactionIdData.action.toLowerCase() === 'send'
                                        ? <img style={{ marginRight: "8px" }} width="16" data-img-theme="light" src={logo} alt="Pecuscan Logo" />
                                        : ""} */}
                                    {transValue(transactionIdData)} USD
                                </Grid>
                            </Grid>
                            <hr />

                            <Grid container spacing={2} style={{ margin: '10px 0' }}>
                                <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                    Trans Type:
                                </Grid>
                                <Grid item xs={6} sm={8} className="bold-text gridContent">
                                    {transactionIdData.trans_type==="default"?"Transfer":"Swap"}
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} style={{ margin: '10px 0' }}>
                                <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                    Origination: 
                                </Grid>
                                <Grid item xs={6} sm={8} className="bold-text gridContent">
                                {transactionIdData.trans_type==="default"?"PecuNovus Terminal":"HootDex"}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} style={{ margin: '10px 0' }}>
                                <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                    Symbol: 
                                </Grid>
                                <Grid item xs={6} sm={8} className="bold-text gridContent">
                                    {transactionIdData?.origin?.toLowerCase() === "hootdex" ? <a href={`https://app.hootdex.net/${transactionIdData.symbol}`} target={'_blank'}>{ transactionIdData.symbol}</a>:"PECU"}
                              
                                </Grid>
                            </Grid>

                            <hr />

                            <Grid container spacing={2} className="gridBody">
                                <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                    Block Address:
                                </Grid>
                                <Grid item xs={8} className="bold-text gridContent " style={{ color: "#0784c3" }}>
                                    {transactionIdData?.key}
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} style={{ margin: '10px 0' }}>
                                <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                    Contract key:
                                </Grid>
                                <Grid item xs={6} sm={8} className="bold-text gridContent">
                                    {transactionIdData.contractKey}
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} style={{ margin: '10px 0' }}>
                                <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                    Public notes:
                                </Grid>
                                <Grid item xs={6} sm={8} className="bold-text gridContent">
                                {transactionIdData?.publicNote}
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} style={{ margin: '10px 0' }}>
                                <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                    Private notes:
                                </Grid>
                                <Grid item xs={6} sm={8} className="bold-text gridContent">
                                {transactionIdData?.privateNote}
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </section>
            </section>
            <Footer/>
        </Box>
    )
}
