/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import SpeedIcon from "@mui/icons-material/Speed";
import { useEffect } from "react";
import axios from "axios";
import logo from './pecunovuslogo33.png'
import PublicLedger from "./publicLedger";
function Content() {
  const [charts, setCharts] = useState([]);
  const [currentValue, setCurrentValue] = useState("");
  const [block, setBlock] = useState('');
  const [cap, setCap] = useState([]);
  const chart = () => {
    axios
      .get("https://mhiservers2.com/wallet/getChartData")
      .then((res) => {
        console.log(res.data, "chart");
        setCharts(res);
      })
      .catch((err) => console.log(err));
  };
  const get_latest_block = () => {
    axios
      .get(`https://mhiservers2.com/wallet/latestSyncedBlock`)
      .then((res) => {
        setBlock(res.data.block_length);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const supplyCoin = () => {
    axios
      .get("https://mhiservers2.com/wallet/calculate_circulating_supply")
      .then((res) => {
        console.log(res, "supplyCoin");
      })
      .catch((err) => console.log(err));
  };

  const dailyVolume = () => {
    axios
      .get("https://mhiservers2.com/wallet/calculate_daily_volume")
      .then((res) => {
        console.log(res, "dailyVolume");
      })
      .catch((err) => console.log(err));
  };

  const marketCap = () => {
    axios
      .get("https://mhiservers2.com/wallet/marketcap")
      .then((res) => {
        setCap(res.data);
      })
      .catch((err) => console.log(err));
  };

  const changeIndexCoin = () => {
    axios
      .get("https://mhiservers2.com/wallet/get_change_index_coin")
      .then((res) => {
        console.log(res, "changeIndexCoin");
      })
      .catch((err) => console.log(err));
  };

  const get_current_index_coin = () => {
    axios.get(`https://mhiservers2.com/wallet/get_current_index_coin`).then((res) => {
   
      setCurrentValue(res.data[0].value);
    });
  };

  useEffect(() => { get_current_index_coin() })
  useEffect(()=>{get_latest_block()},[block])
  useEffect(() => {marketCap()}, []);
  return (
    <>
      <section className="container">
        <Grid container className="contentBox block_shadow" spacing={2}>
          <Grid
            item
            xs={4}
            className="resContent resGrid"
            sx={{ paddingRight: "16px", paddingTop: "0px !important" }}
          >
            <Box sx={{ display: "flex" }}>
              <Box className="Eth_icon">
                <img
                  class="img-fluid"
                  width="18"
                  data-img-theme="light"
                  src={logo}
                  alt="Pecuscan Logo"
                />
              </Box>
              <Box>
                <Typography className="text-grey">PecuNovus Price</Typography>
                <a
                  style={{
                    color: "#111b36",
                    display: "flex",
                    fontSize: "15px !important",
                  }}
                  className="contentFont"
                >
                  ${currentValue}
                 
                  {/* <Typography className="contentFont"> (-2.73%)</Typography> */}
                </a>
              </Box>
            </Box>
            <hr />
            <Box sx={{ display: "flex" }}>
              <Box className="Eth_icon">
                <i>
                  <LanguageIcon />
                </i>
              </Box>
              <Box>
                <Typography className="text-grey">Market Cap</Typography>
                <a
                  style={{ color: "#111b36", display: "flex" }}
                  className="contentFont"
                >
                  ${cap.MARKET_CAP}
                </a>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={4}
            className="resHr resGrid"
            sx={{
              borderLeft: "1px solid #e9ecef",
              paddingRight: "16px",
              paddingTop: "0px !important",
            }}
          >
            <hr style={{ display: "none" }} />
            <Box sx={{ display: "flex" }}>
              <Box className="Eth_icon">
                <i>
                  <DnsOutlinedIcon />
                </i>
              </Box>
              <Box sx={{ flexGrow: "1" }}>
                <Typography className="text-grey">Transactions</Typography>
                <a
                  style={{ color: "#111b36", display: "flex" }}
                  className="contentFont"
                >
                  {/* 1,867.73 M{" "} */}
                </a>
              </Box>
            </Box>
            <hr />
            <Box sx={{ display: "flex" }}>
              <Box className="Eth_icon">
                <i>
                  <SpeedIcon />
                </i>
              </Box>
              <Box sx={{ flexGrow: "1" }}>
                <Typography className="text-grey">
                  Last Finalized Block
                </Typography>
                <Typography>{ block}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={4}
            className="resHr resGrid"
            sx={{
              borderLeft: "1px solid #e9ecef",
              paddingTop: "0px !important",
            }}
          >  
            <hr style={{ display: "none" }} />
            <Typography className="text-grey">
              Transaction History in 14 days
            </Typography>
           
          
          </Grid>
        </Grid>
      </section>
    </>
  );
}

export default Content;
