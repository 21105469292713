import React, { useState } from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import Blockcontent from "./Blockcontent";
import Newblock from "./Newblock";
import Box from "@mui/material/Box";
import "./allblock.css";
import Addsearch from "../AddSearch/Addsearch";
import { useLocation } from "react-router-dom";

function AllBlock() {
  const location = useLocation()
  return (
    <Box>
      {/* <Addsearch/> */}
      <Navbar />
      <section style={{ backgroundColor: "#fafbfd" }}>
        <section className="container">
          <div className="headerBlock">
            <h1>Blocks</h1>
          </div>
          {/* <Blockcontent /> */}
          <Newblock pecuscanData={location.state}/>
        </section>
      </section>
      <Footer />
    </Box>
  );
}
export default AllBlock;
