import React, { useEffect } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import TablePagination from '@mui/material/TablePagination';
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState } from "react";
import url from "../../serverurl";
import { blockValue, getBLockNumber, getBlockSize } from "../../utils";

const columns = [
  { id: "Block", label: "Block Height" },
  { id: "NunmberTXN", label: "No. of Transaction" },
  { id: "ID", label: "Block Size" },
  { id: "Txn", label: "Hash" },
  { id: "Age", label: "Timestamp" },
  { id: "Used", label: "Value" },
  { id: "Limit", label: "Previous Hash" },
  { id: "Reward", label: "NODE" },
  { id: "Burnt", label: "Origination" },
  { id: "Fee", label: "Status", minWidth: 120 },
  // { id: "Recipient", label: "Transactions" },
];

function Newblock() {
  const [pecuscanData, setpecuscanData] = useState([]);

  const [blockPage, setBlockPage] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [PreviousPage, setPreviousPage] = React.useState(true);
  const [nextPage, setNextPage] = React.useState(false);
  const handleChange = (event) => {
    setBlockPage(event.target.value);
  };

  const handleNext = (event) => {
    setPageIndex(pageIndex + 1);
  };
  const handleLastPage = (event) => {
    setPageIndex(Math?.ceil(pecuscanData.totalPages - 1));
  };
  const handlePrevious = (event) => {
    setPageIndex(pageIndex - 1);
  };
  const handleFirstPage = (event) => {
    setPageIndex(Math?.ceil(pecuscanData.totalPages  - 2));
  };
  const transactionsEscrow = () => {
    const body = {
      minLimit: 10,
    };
    axios
      .get(`${url}/blockchain/blocks?page=${pageIndex}&pageSize=${blockPage}`)
      .then((res) => {
        setpecuscanData(res.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    transactionsEscrow();
  }, [])
  useEffect(() => {
    transactionsEscrow();
  }, [pageIndex,blockPage])

  // const viewBlock = () => {
  //   const body = {
  //       minLimit: 10,
  //   };
  //   axios
  //       .get("https://mhiservers2.com/wallet/get_current_index_coin", body)
  //       .then((res) => {
  //           console.log(res.data,"newblock")
  //           // setpecuscanData(res.data);

  //       })
  //       .catch((err) => console.log(err));
  // };
  // useEffect(() => {
  //   viewBlock();
  // }, []);

  return (
    <>
      <section className="container" style={{ padding: "0" }}>
        <div style={{ paddingBottom: "48px" }}>
          <div className="blockCard">
            <div className="cardHeader">
              <div style={{ padding: "16px" }}>
                <div>
                  <span>Total of {getBLockNumber(pecuscanData?.totalPages * blockPage)} blocks</span>
                 
                  {/* <p>(Showing blocks between #{getBLockNumber(pecuscanData?.blocks?.index)} to #{getBLockNumber(pecuscanData.totalPages*blockPage)-blockPage-1})</p> */}
                </div>
              </div>
            </div>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                          className="tableHead"
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pecuscanData?.blocks
                      ?.slice(0 ,  blockPage)
                      .map((data) => {
                        return (
                          <>
                            <TableRow hover>
                              {/* <TableCell><a style={{ color: "rgb(7, 132, 195)" }} >{data.id}</a></TableCell> */}
                              <TableCell><Link to={`/block/${data.hash}`} state={data}><a className="text-truncate" style={{ cursor: 'pointer', color: "rgb(7, 132, 195)" }}>{getBLockNumber(data.index)}</a></Link></TableCell>
                              <TableCell>{ data.data.length}</TableCell>
                              <TableCell>{ getBlockSize(data)}</TableCell>
                              <TableCell><a className='truncateKey'>{data.hash}</a></TableCell>
                              <TableCell>{new Date(data.timestamp).toUTCString()}</TableCell>
                              <TableCell>{blockValue(data.data)} USD</TableCell>
                              <TableCell><a className='truncateKey'>{data.previousHash}</a></TableCell>
                              <TableCell>{data.node}</TableCell>
                              <TableCell>{data.origin}</TableCell>
                              <TableCell>
                                {data?.status ? (
                                  <span className="failButton">
                                    <i>
                                      <CancelIcon />
                                    </i>
                                    Fail
                                  </span>
                                ) : (
                                  <span className="successButton">
                                    <i>
                                      <CheckCircleIcon />
                                    </i>
                                    Success
                                  </span>
                                )}
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ padding: '16px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                  <div>Show:</div>
                  <FormControl >
                    <select
                      value={blockPage}
                      onChange={handleChange}
                      className="paginations"
                    >
                      {[10, 25, 50, 100].map((blockPage) => (
                        <option key={blockPage} value={blockPage}>
                          {blockPage}
                        </option>
                      ))}
                    </select>
                  </FormControl>
                </div>
                <div>
                  <div style={{ display: 'flex', gap: '4px' }}>
                    <button className="firstLast" onClick={handleFirstPage} disabled={pageIndex === 0 }><a>First</a></button>
                    <button className="firstLast" onClick={handlePrevious} disabled={pageIndex === 0  }><i ><ArrowBackIosNewIcon /></i></button>
                    <button className="firstLast">
                      <span>
                        Page {pageIndex + 1} 
                      </span>
                    </button>
                    <button className="firstLast" onClick={handleNext} ><i ><ArrowForwardIosIcon /></i></button>
                    <button className="firstLast" onClick={handleLastPage} disabled={pageIndex === 0 ? nextPage : !nextPage}><a >Last</a></button>
                  </div>
                </div>
              </div>
            </Paper>
          </div>
        </div>
      </section>
    </>
  );
}
export default Newblock;
