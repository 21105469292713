import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import Transactioncontent from "./Transactioncontent";
import Newtransaction from "./Newtransaction";
import Box from "@mui/material/Box";
import "../AddTransaction/addtransaction.css";
import SearchBar from "../Searchbar";
import Addsearch from "../AddSearch/Addsearch";
import { useLocation } from "react-router-dom";

function Addtransaction() {
  const location = useLocation()
  return (
    <Box>
      {/* <Addsearch/> */}
      <Navbar />
      <section style={{ backgroundColor: "#fafbfd" }}>
        <section className="container">
          <div className="headerBlock">
            <h1>Transaction</h1>
          </div>
          {/* <Transactioncontent/> */}
          <Newtransaction transaction={location.state}/>
        </section>
      </section>
      <Footer />
    </Box>
  );
}
export default Addtransaction;
