import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, Popover, Typography } from "@mui/material";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import EastIcon from "@mui/icons-material/East";
import SdStorageOutlinedIcon from "@mui/icons-material/SdStorageOutlined";
import Modal from "react-bootstrap/Modal";
import CloseIcon from '@mui/icons-material/Close';
import "./pecuscan.css"
import PublicLedger from "./publicLedger";
import { Link } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import logo from './pecunovuslogo33.png'
import url from "../serverurl";

function getFormattedTime(timestampString) {
    const timestamp = new Date(timestampString);
  
    // timestamp.setHours(timestamp.getHours() + 8);
    // timestamp.setMinutes(timestamp.getMinutes()-50)
    return timestamp;
}
  
const getBLockNumber = (id) => {
    return parseInt(id)+6000000
}
const blockValue = (trans) => {

   
    return trans.length>0? trans?.reduce((a, b) => Number(a) + Number(b.price),0).toFixed(5):0
}
function Block({ searchBlock }) {
    const [pecuscanData, setpecuscanData] = useState([]);
    const [modalOpen, setOpen] = React.useState(false);
    const [SingleData, setSingleData] = React.useState();
    const [formatTime, setFormatTime] = React.useState();
    const [isLoading, setIsLoading] = useState(true);
    const filterBlock = (searchBlock) => {
        if (searchBlock && searchBlock !== '') {
            console.log(pecuscanData)
            return pecuscanData.filter(
                (e) =>
                    e.id?.toString().includes(searchBlock?.toString()) ||
                    e.public_key?.toLowerCase().includes(searchBlock?.toLowerCase()) ||
                    e.value?.toString().includes(searchBlock?.toString())
            );
        } else {
            return pecuscanData;
        }
    };
    const TimeFormat = () => {
        const time = new Date(SingleData?.date_time).toLocaleString();
        setFormatTime(time);
    };
    useEffect(() => {
        TimeFormat();
    }, [SingleData]);

    const transactionsEscrow = () => {
        const body = {
            minLimit: 10,
        };
        axios
            .get(`${url}/blockchain/blocks?page=1`)
            .then((res) => {
                setpecuscanData(res.data.blocks);
                setIsLoading(false);
            })
            .catch((err) => console.log(err));
    };
    useEffect(() => {
        filterBlock();
        transactionsEscrow();
    }, [modalOpen])

    const handleOpen = (data, index) => {
        setSingleData(data);
        setOpen(true);
    };
    const TransactionsData = () => {
        const body = {
            uid: "106",
            pub_key: "nZdei6wmolhFN3BTlE5XQrhT8vXOLm9nRrqukv5pFMVVSEWPnLCxVhLNXmyjJnwz9cIICry2kercFKic",
        };
        axios
            .post("https://mhiservers2.com/wallet/my_latest_transactions", body)
            .then((res) => {
                console.log(res, "transaction Response");
            })
            .catch((err) => console.log(err));
    };
    const scroll = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }
   
    return (
        <>
            {/* <button onClick={TransactionsData}>TransactionsData</button> */}
            <section className="container" style={{ paddingBottom: "80px" }}>
                <Box
                    sx={{ flexGrow: 1, display: "flex", gap: "16px" }}
                    className="resBlock"
                >
                    <Grid container >
                        <Grid
                            xs={12}
                            style={{
                                borderRadius: "12px",
                                backgroundColor: "white",
                                border: "1px solid #e9ecef",
                                marginBottom: "16px",
                            }}
                            className="block_shadow"
                        >
                            <Box className="block_header">
                                <Typography> Latest Blocks</Typography>
                            </Box>
                            <Box style={{justifyContent:'center', display:'flex'}}>{isLoading && <h5>Loading...</h5>}</Box>
                            <Box className="block_body">
                                <>
                                    {filterBlock(searchBlock)
                                        ?.slice(0, 6)
                                        ?.map((data, index) => {
                                            return (
                                                <>
                                                    <Grid
                                                        spacing={2}
                                                        sx={{ display: "flex", margin: "16px 0" }}
                                                        className="resblockContent"
                                                        // onClick={() => handleOpen(data, index)}
                                                    >
                                                        <Grid xs={7} sx={{ maxWidth: "100%" }}>
                                                            <Grid
                                                                sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: "8px",
                                                                }}
                                                            >
                                                                <Box className="block_icon">
                                                                    <i>
                                                                        <ViewInArIcon />
                                                                    </i>
                                                                </Box>
                                                                <Box className="resblockItem">
                                                                    <Link to={`/block/${data.hash}`} state={data}><a className="text-truncate" style={{cursor:'pointer'}}>{getBLockNumber(data.index)}</a></Link>
                                                                    <Typography className="text-muted">
                                                                        {" "}
                                                                        {getFormattedTime(data.timestamp).toUTCString()}
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            xs={5}
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                                maxWidth: "100%",
                                                            }}
                                                        >
                                                            <Box>
                                                                <Box className="block_gap">
                                                                   
                                                                    <a
                                                                        className="text-truncate"
                                                                        style={{ maxWidth: "128px" }}
                                                                    >
                                                                        {data.node}
                                                                    </a>
                                                                </Box>
                                                                {/* <Box className="block_gap">
                                                            <a className="text-truncate">93 txns </a>
                                                            <Typography className="text-muted">
                                                                in 12 secs
                                                            </Typography>
                                                        </Box> */}
                                                            </Box>
                                                            <Box>
                                                                <p className="block_Eth text-truncate">
                                                                    <b>{blockValue(data.data)} USD</b>
                                                                </p>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>

                                                    <Box className="border_Space"></Box>
                                                </>
                                            );
                                        })}
                                </>
                            </Box>
                            <Box className="block_footer text-grey">
                                <Link to="/allBlock" state={pecuscanData} onClick={scroll}>View all blocks</Link>
                                <i>
                                    <EastIcon />
                                </i>{" "}
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container xs={12}
                        style={{
                            borderRadius: "12px",
                            backgroundColor: "white",
                            border: "1px solid #e9ecef",
                            marginBottom: "16px",
                        }}>
                        <PublicLedger searchBlock={searchBlock} />
                    </Grid>
                 
                </Box>
            </section>
            <Modal
                size="sm"
                // show={modalOpen}
                aria-labelledby="example-modal-sizes-title-sm"
                className="modalSet"
            >
                <Box className="modal-show">
                    <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ paddingLeft: "16px" }}><b>Block Data</b></Typography>
                        <Button sx={{ color: "black" }} onClick={() => setOpen(false)}><CloseIcon /></Button>
                    </Box>
                    <Box sx={{ padding: "0 16px" }}>




                        <Grid container spacing={2} style={{ margin: '10px 0' }}>
                            <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                Block Height:
                            </Grid>
                            <Grid item xs={6} sm={8} className="bold-text gridContent">
                                {SingleData?.index}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ margin: '10px 0' }}>
                            <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                Block Size:
                            </Grid>
                            <Grid item xs={6} sm={8} className="bold-text gridContent">
                                128kb
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ margin: '10px 0' }}>
                            <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                Status:
                            </Grid>
                            <Grid item xs={6} sm={8} className="bold-text gridContent">
                            <span className="successButton">
                                        <i>
                                            <CheckCircleIcon />
                                        </i>
                                        Success
                                    </span>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ margin: '10px 0' }}>
                            <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                Timestamp:
                            </Grid>
                            <Grid item xs={8} className="bold-text gridContent">
                                <i>
                                    <AccessTimeIcon />
                                </i>
                                {formatTime}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ margin: '10px 0' }}>
                            <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                Origination:
                            </Grid>
                            <Grid item xs={8} className="bold-text gridContent">
                                <i>
                                    <AccessTimeIcon />
                                </i>
                                Origination
                            </Grid>
                        </Grid>
                        <hr />

                        {/* <Grid container spacing={2} className="gridBody">
                            <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                To public key:
                            </Grid>
                            <Grid item xs={8} className="bold-text gridContent" style={{ color: "#0784c3" }}>
                                {SingleData?.to_public_key}
                            </Grid>
                        </Grid> */}


                        {/* <Grid container spacing={2} className="gridBody">
                            <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                From:
                            </Grid>
                            <Grid item xs={8} className="bold-text gridContent">
                                {SingleData?.from_uid}
                            </Grid>
                        </Grid> */}

                        {/* <Grid container spacing={2} className="gridBody">
                            <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                to:
                            </Grid>
                            <Grid item xs={8} className="bold-text gridContent">
                                {SingleData?.to_uid}
                            </Grid>
                        </Grid> */}


                        {/* <Grid container spacing={2} style={{ margin: '10px 0' }}>
                            <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                Value:
                            </Grid>
                            <Grid item xs={6} sm={8} className="bold-text gridContent">
                                <img style={{ marginRight: "8px" }} width="16" data-img-theme="light" src={logo} alt="Pecuscan Logo" />
                                {SingleData?.value}
                            </Grid>
                        </Grid> */}

                        {/* <Grid container spacing={2} style={{ margin: '10px 0' }}>
                            <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                Transaction Type:
                            </Grid>
                            <Grid item xs={6} sm={8} className="bold-text gridContent">
                                {SingleData?.trans_type}
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} style={{ margin: '10px 0' }}>
                            <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                Action:
                            </Grid>
                            <Grid item xs={6} sm={8} className="bold-text gridContent">
                                {SingleData?.action}
                            </Grid>
                        </Grid> */}

                    </Box>

                    <Box sx={{ padding: "0 16px" }}>
                        <Grid container spacing={2} className="gridBody">
                            <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                Block Address:
                            </Grid>
                            <Grid item xs={8} className="bold-text gridContent " style={{ color: "#0784c3" }}>
                                {SingleData?.key}
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ padding: "0 16px" }}>

                        <Grid container spacing={2} className="gridBody">
                            <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                                Transaction :
                            </Grid>
                            <Grid item xs={8} className="bold-text gridContent" style={{ color: "#0784c3" }}>
                                {/* {SingleData?.public_key} */}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>

        </>
    );
}
export default Block;

