function getFormattedTime(timestampString) {
    const timestamp = new Date(timestampString);
  
    // timestamp.setHours(timestamp.getHours() + 8);
    // timestamp.setMinutes(timestamp.getMinutes()-50)
    return timestamp;
}
const transValue = (trans) => {
    return (Number(trans.price)*Number(trans.amount)).toFixed(5)
}
  
const getBLockNumber = (id) => {
    return parseInt(id)+6000000
}
const blockValue = (trans) => {

   
    return trans.length>0? trans?.reduce((a, b) => Number(a) + Number(b.price),0).toFixed(5):0
}

  
  const getBlockSize = (data) => {
    return JSON.stringify(data).length >1000?`${JSON.stringify(data).length/1000} Kb` :`${JSON.stringify(data).length} bytes`
  }

module.exports={getFormattedTime,getBLockNumber,blockValue,getBlockSize,transValue}