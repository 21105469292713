import React, { useEffect } from 'react'
import Navbar from '../Navbar'
import { Box, Card, Grid } from '@mui/material'
import '../BlockId/blockid.css'
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useLocation } from 'react-router-dom';
import Footer from '../Footer';
const getBLockNumber = (id) => {
  return parseInt(id)+6000000
}
const blockValue = (trans) => {

 
  return trans.length>0? trans?.reduce((a, b) => Number(a) + Number(b.price),0).toFixed(5):0
}

const getBlockSize = (data) => {
  return JSON.stringify(data).length >1000?`${JSON.stringify(data).length/1000} Kb` :`${JSON.stringify(data).length} bytes`
}
export default function Block() {
  const location = useLocation()
  const blockIdData = location.state;
  const [formatTime, setFormatTime] = React.useState();
  const TimeFormat = () => {
    const time = new Date(blockIdData?.timestamp).toUTCString();
    setFormatTime(time);
  };
  useEffect(() => {
    TimeFormat();
  }, [blockIdData]);
  return (
    <Box>
      <Navbar />
      <section style={{ backgroundColor: "#fafbfd", paddingBottom: '20px' }}>
        <section className="container">
          <div className="headerBlock">
            <div className='blockId'>
              <h1>Block Data</h1>
            </div>
          </div>
          <div className='blockCard'>
            <Box style={{ padding: '8px 18px' }}>
              <Grid container spacing={2} style={{ margin: '10px 0' }}>
                <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                  Block Height:
                </Grid>
                <Grid item xs={6} sm={8} className="bold-text gridContent">
                  {getBLockNumber(blockIdData.index)}
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ margin: '10px 0' }}>
                <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                 No. of Transactions:
                </Grid>
                <Grid item xs={6} sm={8} className="bold-text gridContent">
                  {blockIdData.data.length} 
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ margin: '10px 0' }}>
                <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                  Block Size:
                </Grid>
                <Grid item xs={6} sm={8} className="bold-text gridContent">
                  {getBlockSize(blockIdData.data)} 
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ margin: '10px 0' }}>
                <Grid item xs={6} sm={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                  Status:
                </Grid>
                <Grid item xs={6} sm={8} className="bold-text gridContent">
                <span className="successButton">
                      <i>
                        <CheckCircleIcon />
                      </i>
                      Success
                    </span>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ margin: '10px 0' }}>
                <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                  Timestamp:
                </Grid>
                <Grid item xs={8} className="bold-text gridContent">
                  <i>
                    <AccessTimeIcon />
                  </i>
                  {formatTime}
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ margin: '10px 0' }}>
                <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                  Origination:
                </Grid>
                <Grid item xs={8} className="bold-text gridContent">
                {blockIdData.origin}
                </Grid>
              </Grid>
              <Grid container spacing={2} className="gridBody">
                <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                  Block Address:
                </Grid>
                <Grid item xs={8} className="bold-text gridContent " style={{ color: "#0784c3" }}>
                  {blockIdData?.hash}
                </Grid>
              </Grid>
              <Grid container spacing={2} className="gridBody">
                <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                  Previous Block:
                </Grid>
                <Grid item xs={8} className="bold-text gridContent " style={{ color: "#0784c3" }}>
                  {blockIdData?.previousHash}
                </Grid>
              </Grid>
              <Grid container spacing={2} className="gridBody">
                <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                  Trans:
                </Grid>
                <Grid item xs={8} className="bold-text gridContent" style={{ color: "#0784c3" }}>
                 [{blockIdData.data?.map((trans, i) => <p key={i}>{ trans.hash},</p>)}]
                </Grid>
              </Grid>
              <Grid container spacing={2} className="gridBody">
                <Grid item xs={4} className="gridContent" sx={{ color: "#6c757d", fontSize: "17px" }}>
                  NODE:
                </Grid>
                <Grid item xs={8} className="bold-text gridContent">
                  {blockIdData?.node}
                </Grid>
              </Grid>
            </Box>
          </div>
        </section>
      </section>
      <Footer />
    </Box>
  )
}
